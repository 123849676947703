<template>
  <nav
    :class="[
      {
        'menu--visible': isMenuOpen,
        'menu--live': isLiveYoutube
      },
      `menu--${theme}`
    ]"
    class="menu"
  >
    <button
      v-show="$route.name !== 'Index'"
      @click="
        () => {
          setMenuOpen(true)
          setPopinOpen(false)
        }
      "
      :class="{ 'menu__button--visible': !isMenuOpen }"
      class="menu__button"
    >
      menu
    </button>
    <ul>
      <!-- <li>
        <button @click="openYoutubePlayer">Video</button>
      </li> -->
      <template v-for="groupsRanked in groups">
        <li
          v-for="(group, i) in groupsRanked"
          :key="'group-' + group.id"
          :class="{
            'group--first-of-rank': i === 0
          }"
        >
          <button @click="() => onClick(group)">{{ group.name }}</button>
        </li>
      </template>

      <li
        class="group--first-of-rank"
        v-if="canDownload && (version.pressRelease || version.archive)"
      ></li>
      <li v-if="canDownload && version.pressRelease">
        <a :href="version.pressRelease.url" download>Press Release</a>
      </li>
      <li>
        <a href="https://storage.googleapis.com/summer25/Archive.zip" download
          >Download All</a
        >
      </li>
    </ul>
    <button @click="() => setMenuOpen(false)" class="menu__backButton">
      back
    </button>
  </nav>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'Menu',
  computed: {
    ...mapState({
      isLiveYoutube: state =>
        state.configuration.live ? JSON.parse(state.configuration.live) : 0,
      theme: state =>
        (JSON.parse(state.configuration.live) && 'dark') || 'light',
      isMenuOpen: state => state.isMenuOpen,
      version: state => state.version,
      groups: state => {
        const groups = state.version.groups || []
        const perRankGroups = groups.reduce((acc, group) => {
          if (!acc[~~group.rank]) {
            acc[~~group.rank] = {
              rank: ~~group.rank,
              items: [group]
            }
          } else {
            acc[~~group.rank].items.push(group)
          }
          return acc
        }, {})

        return Object.values(perRankGroups)
          .sort((a, b) => a.rank - b.rank)
          .map(group => group.items)
      },
      userRole: state => state.userRole,
      canDownload: state => state.version.canDownload
    })
  },
  methods: {
    ...mapMutations({
      setMenuOpen: 'setMenuOpen',
      setPopinOpen: 'setPopinOpen',
      setPopinSource: 'setPopinSource',
      setPopinContent: 'setPopinContent'
    }),
    onClick(group) {
      this.setPopinSource('group')
      this.setPopinContent(group)
      this.$nextTick(() => {
        this.setPopinOpen(true)
        this.setMenuOpen(false)
      })
    },
    openYoutubePlayer() {
      this.setPopinSource('embed')
      const item = this.version.gridDesktop.items.find(item => {
        return item.media.type === 'youtube' && item.media.width === 3
      })

      this.setPopinContent(item)
      this.$nextTick(() => {
        this.setPopinOpen(true)
        this.setMenuOpen(false)
      })
    }
  }
}
</script>

<style lang="scss">
.menu {
  position: fixed;
  background-color: var(--white);
  color: var(--black);
  padding: 17px;
  top: 66px;
  left: 0;
  height: calc(100vh - 47px);
  z-index: 3;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);
  z-index: 100;

  .dark &--live &__button {
    color: white;
  }

  &--live &__button {
    color: black;
  }

  .dark & {
    background-color: var(--black);
  }

  @media screen and (min-width: 768px) {
    --gutter: calc(clamp(4px, 0.3vw, 10px) * 4);
    color: var(--white) !important;
    top: 0;
    padding-top: 74px;
    padding-left: 30px;
    transform: translateX(-100%);
    background-color: var(--alpha-black) !important;
    height: 100vh;
    width: calc((100% - var(--gutter)) / 5);
  }

  &--visible {
    transform: translateX(0);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li,
  button {
    font-size: 10px;
    line-height: 26px;
    text-transform: uppercase;

    @media screen and (min-width: 768px) {
      line-height: 12px;
    }
  }

  button {
    background-color: transparent;
    border: none;
    padding: 0;
    color: inherit;
    &:not(.menu__button) {
      display: block;
    }
  }

  .menu__button {
    color: #000;
  }

  &__button {
    &,
    &--visible {
      display: none;
    }

    @media screen and (min-width: 768px) {
      display: block;
      pointer-events: none;
      position: absolute;
      top: 34px;
      right: -17px;
      transform: translateX(100%);
      transition: opacity 16ms steps(1);
      opacity: 0;

      @media screen and (min-width: 768px) {
        right: -30px;
      }

      &--visible {
        transition-delay: 500ms;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__backButton {
    @media screen and (max-width: 767px) {
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      margin-top: 12px;
    }
  }

  a {
    text-decoration: none;
  }

  .group {
    &--first-of-rank:not(:first-child) {
      margin-top: 1em;
    }
  }
}
</style>
