import { LocalStorageWithExpiry } from '@/LocalStorageWithExpiry'
const useAuth = () => {
  const localStorageWithExpiry = new LocalStorageWithExpiry()

  const isLoggedIn = () => {
    return localStorageWithExpiry.getItem('loggedId') !== null
  }

  const setLogin = () => {
    localStorageWithExpiry.setItem('loggedId', 'true', 1000 * 60)
  }

  return {
    setLogin,
    isLoggedIn
  }
}

export default useAuth
